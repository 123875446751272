<template>
    <v-card>
        <v-container v-if="loadingDatas === false">
            <v-card-title>
                <v-icon @click="backToPrevious" class="mr-10">{{ icons.mdiKeyboardBackspace }}</v-icon>
                <v-icon class="mr-3">{{ icons.mdiTrashCan }}</v-icon>
                <!-- <v-icon class="">{{ icons.mdiPrinter }}</v-icon> -->
                <v-spacer></v-spacer>
                <v-card-subtitle class="text-md pa-0" v-if="requestDatas.status === '2'">
                    <v-icon size="13" class="primary--text mr-1">{{ icons.mdiCheckboxMarkedCircle }}</v-icon><span
                        class="primary--text text-caption">Validated</span>
                </v-card-subtitle>

                <v-card-subtitle class="text-md pa-0" v-else-if="requestDatas.status === '0'">
                    <v-icon size="13" class="error--text mr-1">{{ icons.mdiCloseCircle }}</v-icon><span
                        class="error--text text-caption">Rejected</span>
                </v-card-subtitle>

                <v-card-subtitle class="text-md pa-0" v-else>
                    <v-icon size="13" class="secondary--text mr-1">{{ icons.mdiAlertCircle }}</v-icon><span
                        class="secondary--text text-caption">Awaiting validation</span>
                </v-card-subtitle>
            </v-card-title>
            <v-card-title>
                <span>{{ requestDatas.request.title }}</span>
            </v-card-title>
            <v-card-subtitle>
                <span>{{ requestDatas.request.requestype.designation }}</span>
            </v-card-subtitle>
            <v-card-text>
                <v-container>
                    <v-row class="pa-0">
                        <v-col cols="12" class="pa-0 d-flex align-centr">
                            <v-avatar size="40">
                                <v-img height="5rem" :src="require('@/assets/images/avatars/profil-sec.svg')" contain
                                    alt="Connect and socialize">
                                </v-img>
                            </v-avatar>
                            <div class="mx-4 d-bloc">
                                <div v-if="this.$store.getters.getViewrequest.inbox === true">
                                    <h4>
                                        <span>From</span>
                                        <span class="secondary--text" v-if="requestDatas.request.owner === '1'">
                                            {{ requestDatas.request.user.firstname }}
                                        </span>
                                        <span class="secondary--text" v-if="requestDatas.request.owner === '0'">
                                            {{ requestDatas.request.enterprise.name }}
                                        </span>
                                    </h4>

                                    <span v-if="requestDatas.request.owner === '1'">
                                        {{ requestDatas.request.user.email }}
                                    </span>
                                    <span v-if="requestDatas.request.owner === '0'">
                                        {{ requestDatas.request.enterprise.email }}
                                    </span>
                                </div>
                                <div v-else>
                                    <h4>
                                        <span>To</span>
                                        <span class="secondary--text"
                                            v-if="this.$store.getters.getViewrequest.owner === 'user'">
                                            {{ requestDatas.user.firstname }}
                                            <!-- {{ requestDatas.user.email }} -->
                                        </span>
                                        <span class="secondary--text"
                                            v-if="this.$store.getters.getViewrequest.owner === 'company'">
                                            {{ requestDatas.enterprise.name }}
                                            <!-- {{ requestDatas.enterprise.email }} -->
                                        </span>
                                    </h4>
                                    <span v-if="this.$store.getters.getViewrequest.owner === 'user'">
                                        {{ requestDatas.user.email }}
                                    </span>
                                    <span v-if="this.$store.getters.getViewrequest.owner === 'company'">
                                        {{ requestDatas.enterprise.email }}
                                    </span>
                                </div>
                            </div>
                            <v-spacer></v-spacer>
                            <span>{{ requestDatas.sent_at }}</span>
                        </v-col>
                        <v-col cols="12" class="">
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <v-card elevation="0" tile>
                                <v-card-title class="subaccent">{{ requestDatas.request.object }}
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-title class="">
                                    {{ requestDatas.request.owner === '1' ?
                                        (requestDatas.request.user.firstname
                                            + '-' + requestDatas.request.user.lastname) :
                                        (requestDatas.request.owner === '0' ?
                                            requestDatas.request.enterprise.name : '')
                                    }}</v-card-title>
                                <v-card-subtitle>
                                    <v-icon small>{{ icons.mdiMapMarkerOutline }}</v-icon>

                                    {{ requestDatas.request.owner === '1' ?
                                        requestDatas.request.user.country.name :
                                        (requestDatas.request.owner === '0' ?
                                            requestDatas.request.enterprise.country.name : '')
                                    }}
                                </v-card-subtitle>

                                <span> </span>

                                <v-card-text v-html="requestDatas.request.content">
                                    <!-- {{ requestDatas.content }} -->
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card-subtitle class="subaccent">
                            </v-card-subtitle>
                        </v-col>
                    </v-row>
                    <v-row v-if="this.$store.getters.getViewrequest.inbox === true">
                        <v-col v-if="requestDatas.status === '1'">
                            <accept-request :acceptation="false" :isUser="requestDatas.status === '1' ? true : false"
                                :ids="this.$store.getters.getViewrequest.id">
                            </accept-request>
                            <accept-request :acceptation="true" :isUser="requestDatas.status === '1' ? true : false"
                                :ids="this.$store.getters.getViewrequest.id">
                            </accept-request>
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>
        </v-container>
        <v-container v-else>
            <v-col cols="12">
                <v-skeleton-loader v-bind="attrs" type="card-avatar, article, actions"></v-skeleton-loader>
            </v-col>
        </v-container>
    </v-card>
</template>

<script>
import { mdiKeyboardBackspace, mdiTrashCan, mdiPrinter, mdiCalendarRange, mdiMapMarkerOutline, mdiCheckboxMarkedCircle, mdiCloseCircle, mdiAlertCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import AcceptRequest from '@/views/dashboard/requests/components/AcceptRequest.vue'

export default {
    data() {
        return {
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
            loadingDatas: true,
            mydataview: [],
            requestDatas: {},
            company: 'company',
            user: 'user',
        }
    },
    components: {
        AcceptRequest
    },
    beforeMount() {
        var typereq = ''
        this.loadingDatas = true
        if (this.$store.getters.getViewrequest.type === 'RFP') {
            typereq = `lazyloading.rfpenterprise?dfilters=on&id:eq=${this.$store.getters.getViewrequest.id}`
        }
        if (this.$store.getters.getViewrequest.type === 'RFC') {
            if (this.$store.getters.getViewrequest.owner === 'user') {
                typereq = `lazyloading.rfcuser?dfilters=on&id:eq=${this.$store.getters.getViewrequest.id}`
            }
            if (this.$store.getters.getViewrequest.owner === 'company') {
                typereq = `lazyloading.rfcenterprise?dfilters=on&id:eq=${this.$store.getters.getViewrequest.id}`
            }
        }
        Drequest.api(typereq)
            .get((response) => {
                if (response.success === true) {
                    this.requestDatas = {
                        id: response.listEntity[0].id,
                        status: response.listEntity[0].status,
                        user: response.listEntity[0].user,
                        enterprise: response.listEntity[0].enterprise,
                        request: this.$store.getters.getViewrequest.type === 'RFP' ? response.listEntity[0].rfp : response.listEntity[0].rfc,
                        sent_at: response.listEntity[0].sent_at
                    }
                    console.log(this.requestDatas)
                    this.loadingDatas = false
                } else {
                    this.$fire({
                        title: "Erreur inattendue!",
                        type: "error",
                        text: "Veuillez recharger la page..!",
                        timer: 3000
                    })
                    this.loadingDatas = false
                }
            })
            .catch((err) => {
                console.log("err")
                this.loadingDatas = false
            })
    },
    methods: {
        backToPrevious() {
            // alert(this.$route.params.ids)
            this.$router.go(-1)
        },
        rejectRequest() {
        },
        acceptRequest() {

        },
    },
    setup() {
        const icons = {
            mdiKeyboardBackspace, mdiTrashCan, mdiPrinter, mdiCalendarRange,
            mdiMapMarkerOutline, mdiMapMarkerOutline, mdiCheckboxMarkedCircle, mdiCloseCircle, mdiAlertCircle,
        }
        return {
            icons,
        }
    }
}
</script>

<style lang="scss" scoped>
.request-read-content {
    border: solid 1px;
    margin-top: 2rem;
}
</style>